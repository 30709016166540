.left-panel {
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  background: rgb(3, 77, 124);
  color: #eee;
  overflow: auto;
  padding: 2em;
  max-width: 25em;
  transform: translateX(0%);
  transition: .3s ease-out;
  z-index: 98;
}

.left-panel-open-handle {
  position: absolute;
  right: 5px;
  top: 10px;
  color: black;
}

.left-panel-close-handle {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: rgb(3, 77, 124);
  color: black;
}

.left-panel-icon {
  color: white;
  position: relative;
  left: 3px;
}

.left-panel-title {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: white;
  font-size: 20px;
  margin: 20px 0 0 0;
  position: relative;
  left: 9px;
}

.left-panel-button {
  text-align: right;
}

.left-panel-close {
  margin-left: 2em;
  transform: translateX(-100%);
  transition: .3s ease-out;
  overflow: hidden;
}

.big {
  font-size: 150%;
}

a {
  text-decoration: none;
  color: #898989;
}

a:hover {
  background-color: #b3cee1;
  color: #898989;
  text-decoration: none;
}

.small-image {
  max-height: 100px;
  padding: 1em;
}

h3 {
  font-size: 150%;
  margin-top: 30px;
}

h2 {
  margin-bottom: 30px;
}

div[disabled] {
  pointer-events: none;
  background-color: #d7d7d7;
}

.min-image {
  width: 150px;
  padding: 5px;
  cursor: pointer;
}

.list-group-item.active {
  background-color: rgb(3, 77, 124);
  border-color: white;
}

.list-group-item:hover {
  background-color: #d7d7d7;
}

.list-group-item {
  background-color: white;
  padding: .5rem 1rem;
  border: 1px solid rgba(0,0,0,.125);
}

.logo {
  max-height: 35px;
  max-width: 35px;
}

.button-link {
  background: none;
  border: none;
  padding: 0!important;
  color: #898989;
  text-decoration: none;
  cursor: pointer;
}

.button-link:hover {
  background-color: #b3cee1;
  color: #898989;
  text-decoration: none;
}
