.linker-component {
    position: absolute;
    right: 100px;
    top: 25px
}

.linker-component:focus {
    outline: none;
}

.icon-black {
    color: black;
}

a.disabled {
    color: lightgray;
    pointer-events: none;
}

.dd {
    position: absolute;
    top: 80px;
    right: 55px;
    width: 230px;
    background-color: white;
    border: 1px solid black;
    z-index: 1000;
    padding: 10px;
    box-shadow: 6px 6px 5px 5px rgba(0, 0, 0, .50);
}

.dd:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 70%;
    margin-left: -12px;
    width: 0; height: 0;
    border-bottom: 12px solid black;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
}

.dd:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 70%;
    margin-left: -11px;
    width: 0; height: 0;
    border-bottom: 11px solid white;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
}

@media screen and (max-width: 640px) {
    .linker-component {
        display: none;
    }
}
