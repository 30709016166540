.panel {
  position: absolute;
  top: 80px;
  bottom: 0;
  right: 0;
  background: rgb(3, 77, 124);
  color: #eee;
  overflow: auto;
  padding: 2em;
  max-width: 33.7em;
  transform: translateX(0%);
  transition: .3s ease-out;
  margin-left: 2em;
  margin-bottom: 3%;
  z-index: 98;
}

.panel-open-handle {
  position: absolute;
  left: 5px;
  top: 10px;
  color: black;
}

.panel-close-handle {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: rgb(3, 77, 124);
  color: black;
}

.panel-icon {
  color: white;
  position: relative;
  right: 4px;
}

.panel-title {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: white;
  font-size: 20px;
  margin: 20px 0 0 0;
}

.panel-button {
  text-align: right;
}

.panel-close {
  margin-right: 2em;
  transform: translate(100%, 0%);
  transition: .3s ease-out;
  position: absolute;
  top: 80px;
  bottom: 0;
  right: 0;
  background: rgb(3, 77, 124);
  color: #eee;
  padding: 1em;
  margin-left: 2em;
  margin-bottom: 3%;
  z-index: 98;
}

.table-white-font {
  color: white;
}

.anatomie-html img {
  max-width: 90%;
}

.anatomie-html-close {
  display: none;
}
