.btn-jumbotron:hover {
    background-color: rgb(3, 77, 124);
    border-color: rgb(3, 77, 124);
    color: #d7d7d7;
}

.btn-jumbotron:active {
    background-color: rgb(3, 77, 124) !important;
    border-color: rgb(3, 77, 124) !important;
    color: #d7d7d7 !important;
}

.btn-jumbotron {
    background-color: white;
    border-color: white;
    color: rgb(73, 80, 87);;
}

.jumbotron-mod {
    position: absolute;
    top: 120px;
    height: calc(100vh - 210px);
    left: calc(50% - 40vw);
    width: 80vw;
    background-color: #d7d7d7;
    padding: 20px;
    overflow: auto;
}

.main-question {
    font-size: 200%;
    font-weight: bold;
}

.question-title {
    font-size: 120%;
}

.img-jumbotron {
    max-height: 40vh;
    position: relative;
}

.btn-jumbotron-group {
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.small-icon-img {
    height: 35px;
    margin-right: 20px;
}

.big-left-margin {
    margin-left: 250px;
    margin-top: 100px;
}

.img-right {
    float: right;
    width: 450px;
    padding: 50px;
}

.jumbotron-scrollable {
    overflow: scroll;
}

.img-small {
    width: 450px;
    margin: 20px;
}

.really-small {
    position: relative;
    top: -25px;
}

.jumbotron-inside {
    padding-bottom: 20px;
}

.img-clickable {
    cursor: pointer;
}

.footer-outside-align {
    position: fixed;
    left: 200px;
    bottom: 12px;
    background-color: #034d7c;
    color: #fff;
}

.logo-big {
    width: 80%;
    margin-bottom: 5%;
}

.center-text {
    text-align: center
}

.title-page-margin {
    margin: 3%;
    text-align: center;
}

.title-page-margin:after {
    display: inline-block;
}

.btn-bottom-right {
    position: absolute;
    margin-top: 3%;
    bottom: 20px;
    right: 20px;
}

.btn-bottom-left {
    position: absolute;
    margin-top: 3%;
    bottom: 20px;
    left: 20px;
}

.img-small-list {
    max-height: 200px;
}

@media screen and (max-width: 640px) {
    .main-question {
        font-size: initial;
        font-weight: initial;
    }

    .img-right {
        float: initial;
        width: 100%;
        padding: initial;
    }

    .img-small {
        width: 100%;
        margin: initial;
    }
    
    .jumbotron-mod {
        top: 80px;
    }

    .footer-outside-align {
        left: initial;
        right: 57px;
        bottom: 60px;
    }

    .btn-jumbotron-group {
        right: initial;
    }

    .btn-jumbotron {
        margin-bottom: 5px;
    }

    .btn-bottom-right {
        position: initial;
    }
    
    .btn-bottom-left {
        position: initial;
    }
    
    .big-left-margin {
        margin-left: initial;
        margin-top: initial;
    }

}
