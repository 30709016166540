@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

body {
  overflow: hidden;
}

.App {
  font-family: 'Source Sans Pro', sans-serif;
}

@media screen and (min-width: 640px) {
  .App-h1 {
    color: white;
    font-weight: 700;
    font-size: 11px;
  }

  .App-h2 {
    color: rgb(3, 77, 124);
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;
    width: 170px;
  }

  .App-header-logo {
    height: 60px;
  }

  .App-header {
    background-color: #d7d7d7;
    display: flex;
    align-items: center;
    color: white;
    height: 80px;
    padding-top: 10px;
    padding-right: 40px;
  }

  .App-header-item {
    margin: 0;
    padding-left: 30px;
  }

  .end {
    margin-left: auto;
  }

  .App-article {
    height: calc(100vh - 80px);
    margin-left: 2em;
    margin-right: 2em;
  }

  .App-link {
    color: #61dafb;
  }
}

@media screen and (max-width: 640px) {
  .App-h2 {
    display: none;
  }

  .App-header-logo {
    height: 40px;
  }

  .App-header {
    background-color: #d7d7d7;
    display: flex;
    align-items: center;
    color: white;
    height: 60px;
    padding-top: 10px;
    padding-right: 10px;
  }

  .App-header-item {
    margin: 0;
    padding-left: 10px;
  }

  .end {
    margin-left: auto;
  }

  .App-article {
    height: calc(100vh - 80px);
    margin-left: 2em;
    margin-right: 2em;
  }

  .App-link {
    color: #61dafb;
  }
}
