.footer-panel {
  position: absolute;
  bottom: 0;
  width: 33.7em;
  right: 0;
  background: rgb(3, 77, 124);
  color: white;
  height: calc(100% - 80px);
  transition: .3s ease-out;
  z-index: 99;
  padding: 2em;
  overflow: auto;
}

.footer-panel-close {
  height: 50px;
  overflow: hidden;
  padding: 0;
}

.footer-right-align {
  position: absolute;
  text-align: right;
  padding-right: 3em;
  right: 20px;
  bottom: 12px;
}

.footer-left-align {
  position: absolute;
  left: 30px;
  bottom: 12px;
  background-color: #034d7c;
  color: #fff;
}

.left-panel-close-handle {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: rgb(3, 77, 124);
  color: black;
}

.left-panel-icon {
  color: white;
}

.footer-version {
  position: absolute;
  text-align: right;
  padding-right: 3em;
  right: 20px;
  bottom: -3px;
  color: grey;
}
